import { Box } from '@mui/material';
import Carousel from 'react-multi-carousel';

import ArrowCircleButton from 'src/components/atoms/arrowCircleButton';
import ContinueLearningCard from 'src/components/molecules/continueLearning/card';
import { useWindowSize } from 'src/helpers/use';
import { IEnrollment } from 'src/interfaces';

type Props = {
  enrollments: IEnrollment[];
};

const ContinueLearningCardCarousel = ({ enrollments }: Props) => {
  const { width } = useWindowSize();

  const CARD_WIDTH = 300;
  const cardCount = Math.floor(width! / CARD_WIDTH);
  const gutter = (width! % CARD_WIDTH) / cardCount;
  const responsive = {
    largeDesktop: {
      breakpoint: { max: 6000, min: 2000 },
      items: 6,
      slidesToSlide: 6,
    },
    mediumDesktop: {
      breakpoint: { max: 1999, min: 1701 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 1700, min: 1200 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: cardCount,
      slidesToSlide: cardCount,
      partialVisibilityGutter: enrollments?.length <= cardCount ? 0 : gutter,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: cardCount,
      slidesToSlide: cardCount,
      partialVisibilityGutter: enrollments?.length <= cardCount ? 0 : gutter,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      partialVisible
      draggable={width! < 1200}
      swipeable={width! < 1200}
      containerClass="carousel-container"
      ssr
      itemClass={`carousel-item ${enrollments?.length <= cardCount && 'width'}`}
      customRightArrow={<ArrowCircleButton isFixTop direction="right" />}
      customLeftArrow={<ArrowCircleButton isFixTop direction="left" />}
      removeArrowOnDeviceType={['tablet', 'mobile']}
    >
      {enrollments ? (
        enrollments?.map((enrollment) => (
          <ContinueLearningCard
            key={enrollment.id}
            course={enrollment.courses}
            totalAmount={enrollment.course_items_amount}
            finishedAmount={enrollment.finished_course_items_amount}
          />
        ))
      ) : (
        <Box />
      )}
    </Carousel>
  );
};

export default ContinueLearningCardCarousel;
